// carpet floor images are imported here
import img1 from "../Assets/carpets/1.png";
import img2 from "../Assets/carpets/2.png";
import img3 from "../Assets/carpets/3.png";
import img4 from "../Assets/carpets/4.png";
import img5 from "../Assets/carpets/5.png";
import img6 from "../Assets/carpets/6.png";
import img7 from "../Assets/carpets/7.png";
import img8 from "../Assets/carpets/8.png";
import img9 from "../Assets/carpets/9.png";
import img10 from "../Assets/carpets/10.png";
import img11 from "../Assets/carpets/11.png";
import img12 from "../Assets/carpets/12.png";
import img13 from "../Assets/carpets/13.png";
import img14 from "../Assets/carpets/14.png";
import img15 from "../Assets/carpets/15.png";
import img16 from "../Assets/carpets/16.png";
import img17 from "../Assets/carpets/17.png";
import img18 from "../Assets/carpets/18.png";
import img19 from "../Assets/carpets/19.png";

// ceiling images imported here
import ceil1 from "../Assets/ceiling/1.png";
import ceil2 from "../Assets/ceiling/2.png";
import ceil3 from "../Assets/ceiling/3.png";
import ceil4 from "../Assets/ceiling/4.png";
import ceil5 from "../Assets/ceiling/5.png";
import ceil6 from "../Assets/ceiling/6.png";
import ceil7 from "../Assets/ceiling/7.png";
import ceil8 from "../Assets/ceiling/8.png";

// Doors images imported here
import door1 from "../Assets/doors/1.png";
import door2 from "../Assets/doors/2.png";
import door3 from "../Assets/doors/3.png";
import door4 from "../Assets/doors/4.png";
import door5 from "../Assets/doors/5.png";
import door6 from "../Assets/doors/6.png";
import door7 from "../Assets/doors/7.png";
import door8 from "../Assets/doors/8.png";
// Array of navigation items
export const navItems = [
  { to: "/", text: "Home", cls: "home" },
  { to: "/about", text: "About US", cls: "about" },
  { to: "/service", text: "Services", cls: "services" },
  { to: "/contact", text: "Contact US", cls: "contact" },
];

export const carpetsData = {
  pvcMaterials: {
    title: "PVC Materials",
    data: [
      {
        image: img1,
        batch: "001",
      },
      {
        image: img2,
        batch: "002",
      },
      {
        image: img3,
        batch: "003",
      },
      {
        image: img4,
        batch: "004",
      },
    ],
  },
  woodenMaterials: {
    title: "PVC Wooden Materials",
    data: [
      {
        image: img5,
        batch: "005",
      },
      {
        image: img6,
        batch: "006",
      },
      {
        image: img7,
        batch: "007",
      },
      {
        image: img8,
        batch: "008",
      },
    ],
  },
  marbleMaterials: {
    title: "PVC Marble Finish Materials",
    data: [
      {
        image: img9,
        batch: "009",
      },
      {
        image: img10,
        batch: "0010",
      },
      {
        image: img11,
        batch: "0011",
      },
      {
        image: img12,
        batch: "0012",
      },
    ],
  },
  carpetMaterials: {
    title: "PVC Carpet Finish Materials",
    data: [
      {
        image: img13,
        batch: "0013",
      },
      {
        image: img14,
        batch: "0014",
      },
      {
        image: img15,
        batch: "0015",
      },
      {
        image: img16,
        batch: "0016",
      },
    ],
  },
  flooringPattern: {
    title: "Flooring Pattern",
    data: [
      {
        image: img17,
        batch: "0017",
      },
      {
        image: img18,
        batch: "0018",
      },
      {
        image: img19,
        batch: "0019",
      },
    ],
  },
  ceilingData: {
    title: "Elevator Ceiling",
    data: [
      {
        image: ceil1,
        batch: "001",
      },
      {
        image: ceil2,
        batch: "002",
      },
      {
        image: ceil3,
        batch: "003",
      },
      {
        image: ceil4,
        batch: "004",
      },
      {
        image: ceil5,
        batch: "005",
      },
      {
        image: ceil6,
        batch: "006",
      },
      {
        image: ceil7,
        batch: "007",
      },
      {
        image: ceil8,
        batch: "008",
      },
    ],
  },
  doorData: {
    title: "Elevators Doors",
    data: [
      {
        image: door1,
        batch: "001",
      },
      {
        image: door2,
        batch: "002",
      },
      {
        image: door3,
        batch: "003",
      },
      {
        image: door4,
        batch: "004",
      },
      {
        image: door5,
        batch: "005",
      },
      {
        image: door6,
        batch: "006",
      },
      {
        image: door7,
        batch: "007",
      },
      {
        image: door8,
        batch: "008",
      },
    ],
  },
};
