import React from "react";
import OurProject from "../../Components/OurProject/OurProject";
import LandingCards from "../../Components/LandingCards/LandingCards";
import CarouselCard from "../../Components/Carousel/Carousel";
import HeroSection from "../../Components/HeroSection/HeroSection";
import CabinsCard from "../../Components/CabinsCard/CabinsCard";
import ContactCard from "../../Components/ContactCard/ContactCard";
import { services } from "../../Utils/services/constant.js";
const Home = () => {
  return (
    <>
      <CarouselCard />
      <HeroSection />
      <CabinsCard data={services?.elevatorCabins?.data} />
      <LandingCards />
      <OurProject />
      <ContactCard />
    </>
  );
};

export default Home;
