import cabin1Img from "../../Assets/cabins/premSsCabin1.PNG";
import cabin2Img from "../../Assets/cabins/premSsCabin2.PNG";
import cabin3Img from "../../Assets/cabins/premSsCabin3.PNG";
import cabin4Img from "../../Assets/cabins/premSsCabin4.PNG";
import cabin5Img from "../../Assets/cabins/premSsCabin5.PNG";
import cabin6Img from "../../Assets/cabins/premSsCabin6.PNG";
import cabin7Img from "../../Assets/cabins/premSsCabin7.PNG";

export const premiumSsCabinCardData = [
  {
    title: "Luxury Stainless Steel Cabin",
    imageUrl: cabin1Img,
    imageHeight: "h-80",
    object: "object-contain",
    imgClass: "w-2/5",
    description:
      "Indulge in luxury with our premium stainless steel elevator cabins. Crafted with the finest materials and exquisite design, our cabins offer a sophisticated and comfortable riding experience.",
  },
  {
    title: "Sleek SS Elevator Cabin",
    imageUrl: cabin2Img,
    imageHeight: "h-80",
    object: "object-contain",
    imgClass: "w-2/5",
    description:
      "Experience elegance and style with our sleek stainless steel elevator cabins. With modern design elements and smooth finishes, our cabins elevate the ambiance of any building.",
  },
  {
    title: "Custom SS Cabins",
    imageUrl: cabin3Img,
    imageHeight: "h-80",
    object: "object-contain",
    imgClass: "w-2/5",
    description:
      "Tailor your elevator cabins to reflect your unique taste and preferences. Our custom stainless steel cabins offer a range of customization options for a personalized riding experience.",
  },
  {
    title: "Premium SS Cabin Design",
    imageUrl: cabin4Img,
    imageHeight: "h-80",
    object: "object-contain",
    imgClass: "w-2/5",
    description:
      "Elevate your building's interior with our premium stainless steel cabin designs. With attention to detail and superior craftsmanship, our cabins add sophistication and charm to any space.",
  },
  {
    title: "Stainless Steel Cabin Innovations",
    imageUrl: cabin5Img,
    imageHeight: "h-80",
    object: "object-contain",
    imgClass: "w-2/5",
    description:
      "Stay ahead with the latest innovations in stainless steel elevator cabins. Our cutting-edge designs and technology ensure optimal performance and comfort for passengers.",
  },
  {
    title: "Stylish SS Elevator Interiors",
    imageUrl: cabin6Img,
    imageHeight: "h-80",
    object: "object-contain",
    imgClass: "w-2/5",
    description:
      "Enhance the aesthetic appeal of your elevator interiors with our stylish stainless steel designs. With sleek finishes and modern accents, our cabins create a lasting impression.",
  },
  {
    title: "Elegant Stainless Steel Cabin",
    imageUrl: cabin7Img,
    imageHeight: "h-80",
    object: "object-contain",
    imgClass: "w-2/5",
    description:
      "Add a touch of elegance to your building's vertical transportation with our stainless steel elevator cabins. Combining beauty and functionality, our cabins elevate the overall experience for passengers.",
  },
];
