import React from 'react'
import CarpetCard from '../../Components/CarpetCard/CarpetCard'
import { carpetsData } from '../../Utils/Utils'
const Doors = () => {
  return (
    <>
    <h1 className="text-primary ml-2">Doors</h1>
      <CarpetCard pvcMaterials={carpetsData?.doorData} />
    </>
  )
}

export default Doors