import React from "react";
import { EvCard } from "../../Components/EvCard";
import { capsuleElevatorCardData } from "../../Utils/CapsuleElevator/constant";
import EvTypography from "../../Components/EvTypography";
import ElevatorCabinsCard from "../../Components/CabinsCard/CabinsCard";

const CapsuleElevator = () => {
  return (
    // <div>
    //   <EvTypography
    //     additionalClass="text-center"
    //     type="h1"
    //     text="CAPSULE ELEVATOR"
    //   />
    //   <div className="m-">
    //     <EvCard cardData={capsuleElevatorCardData} descriptionClass="" />
    //   </div>
    // </div>
    <>
    <ElevatorCabinsCard data={capsuleElevatorCardData}/>
    </>
  );
};

export default CapsuleElevator;
