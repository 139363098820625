import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { navItems } from "../../Utils/Utils";
import NestedDropdown from "../NestedDropdown";
import headerLogo from "../../Assets/headerLogo.png";
const NavbarSection = () => {
  const [navbar, setNavbar] = useState(false);
  const [show, setShow] = useState(false);
  const [menu, setMenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setNavbar(false);
  }, [location]);
  const closeNavbar = () => {
    setNavbar(!navbar);
    handleMouseLeave();
  };
  const handleMouseEnter = () => {
    setShow(true);
  };
  const handleMouseLeave = () => {
    setShow(false);
    setMenu(false); // Close menu when moving away from the button
  };

  return (
    <nav className="w-full sticky top-0 z-10 bg-white shadow">
      <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center justify-between  md:block">
            <NavLink to="/">
              <img src={headerLogo} width={250} height={70} alt="logo" />
            </NavLink>

            <div className="md:hidden">
              <button
                className="p-2 rounded-md outline-none  border-none bg-white"
                onClick={closeNavbar}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-primary"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-primary"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <div className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
              <NestedDropdown
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                show={show}
                setShow={setShow}
                menu={menu}
                setMenu={setMenu}
              />
              {navItems.map((item, index) => (
                <NavLink
                  style={{ textDecoration: "none" }}
                  key={index}
                  to={item.to}
                  exact
                  className={`hover:text-primary m-2 ${
                    location.pathname === item.to
                      ? "text-primary"
                      : "text-gray-500"
                  }`}
                  onClick={closeNavbar}
                >
                  <span>
                    <p>{item.text}</p>
                  </span>
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default NavbarSection;
