import React from "react";
import QRCode from "react-qr-code";
const BarcodeScan = () => {
  const websiteUrl = "https://52gates.com";
  return (
    <div className="flex justify-center items-center flex-col">
      <h1 className="my-4 text-primary">The 52 Gates Elevator Pvt Ltd ...</h1>
      <QRCode value={websiteUrl} />
    </div>
  );
};

export default BarcodeScan;
