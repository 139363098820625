import React, { useEffect } from "react";
import Inputs from "../../Components/Inputs/Inputs";
import contactUs from "../../Assets/contactUs.jpg";

import { useState } from "react";
import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://formspree.io/f/mjvnanvw", formData);
      // Clear form data upon successful submission
      setFormData({
        userName: "",
        email: "",
        subject: "",
        message: "",
      });
      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to submit form. Please try again.");
    }
  };

  return (
    <div className="relative">
      <div
        className="md:h-96 h-80 bg-cover bg-center flex items-center justify-center relative"
        style={{ backgroundImage: `url(${contactUs})` }}
      >
        {/* Heading placed on top of the background image */}
        <h1 className="text-primary text-4xl font-bold animate-bounce">
          Contact Us
        </h1>
      </div>
      <section className="bg-white ">
        <div className=" md:px-4 mx-auto max-w-screen-md">
          <h2 className="my-4 text-primary text-4xl tracking-tight font-extrabold text-center">
            Get In Touch
          </h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-500  sm:text-xl">
            Have questions about our elevator services? Want to discuss a
            project? Drop us a message.
          </p>
          <form onSubmit={handleSubmit} className="space-y-8 w-[97%]">
            <Inputs
              label={"User Name"}
              placeholder={"please Enter your name"}
              required={true}
              type={"text"}
              name={"userName"}
              value={formData.userName}
              onChange={handleChange}
            />
            <Inputs
              label={"Your Email"}
              placeholder={"example@gmail.com"}
              required={true}
              type={"email"}
              name={"email"}
              value={formData.email}
              onChange={handleChange}
            />
            <Inputs
              label={"Subject"}
              placeholder={"Let us know how we can help you"}
              required={true}
              type={"text"}
              name={"subject"}
              value={formData.subject}
              onChange={handleChange}
            />
            <div className="sm:col-span-2 ml-1">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-primary "
              >
                Your message
              </label>
              <textarea
                id="message"
                rows="6"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="block py-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500   placeholder:pl-1 pl-1.5"
                placeholder="Leave a comment..."
              ></textarea>
            </div>
            <button
              type="submit"
              className="py-3 md:ml-0 ml-2 px-5 text-sm font-medium text-center text-white border-none  rounded-lg bg-primary sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 "
            >
              Send message
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
