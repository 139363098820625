import React, { useEffect } from "react";
import aboutUsImage from "../../Assets/aboutUs.jpg";
import { Link } from "react-router-dom";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Hero Section */}
      <div
        className="md:h-96 h-80 bg-cover bg-center flex items-center justify-center relative"
        style={{ backgroundImage: `url(${aboutUsImage})` }}
      >
        {/* Heading placed on top of the background image */}
        <h1 className="text-primary text-4xl font-bold animate-bounce">
          About Us
        </h1>
      </div>

      {/* Main Content */}
      <div className="max-w-screen-md mx-auto px-4 py-8">
        <h2 className="mb-8 text-primary md:text-4xl text-2xl font-extrabold text-center ">
          Welcome to 52 Gates Elevators Pvt. Ltd.
        </h2>
        <p className="mb-8 text-lg text-start text-gray-700 ">
          At 52 Gates Elevators, we are dedicated to providing top-notch
          elevator solutions tailored to your needs. With years of experience in
          the industry, we offer a wide range of elevator products and services,
          including installation, maintenance, and modernization. Our team of
          experts is committed to delivering reliable and efficient elevator
          systems that enhance the vertical transportation experience for our
          clients.
        </p>

        <Link to="/barcodePage">
          <p>Barcode scan</p>
        </Link>

        {/* Location Section */}
        <div className="mb-12 mt-4">
          <h3 className="mb-4 text-2xl font-bold text-primary ">
            Visit Our Location
          </h3>
          <div className="flex justify-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3751.843617836962!2d75.317497873761!3d19.888824225955968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa46f67073dd867a3%3A0x2dd1d9988da15a15!2s52%20gates%20elevators%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1710591129656!5m2!1sen!2sin"
              width="90%"
              height="400"
              className="rounded-lg"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="mymap"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
