import React from "react";
import { Link } from "react-router-dom";

const ContactCard = () => {
  return (
    <section className="">
      <div className=" flex justify-center flex-col mx-auto lg:flex-row">
        <div className="flex flex-col justify-center items-center w-full lg:w-2/4 md:p-8 lg:p-12">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="#002e5f"
            className="w-8 px-2 h-8 mb-8 "
          >
            <path
              fillRule="evenodd"
              d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            ></path>
          </svg>
          <h2 className="md:text-5xl text-3xl px-2 font-semibold leading-none">
            Get In Touch With Us
          </h2>
          <p className="mt-4 px-2 mb-8 text-sm text-gray-500">
            Reach Out for Elevator Solutions
          </p>
          <Link to="/contact">
            <button className="md:self-start bg-primary text-white mx-2 px-10 py-3 text-lg font-medium rounded-3xl ">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ContactCard;
