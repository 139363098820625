import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ElevatorCabinsCard = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="p-4 lg:p-8">
      <div className="mx-auto space-y-12">
        {data?.map((cabin, index) => (
          <div
            key={cabin.id}
            className={`flex flex-col overflow-hidden rounded shadow-sm lg:flex-row ${
              index % 2 === 0 ? "lg:flex-row-reverse" : ""
            }`}
          >
            <img
              src={cabin.imageUrl}
              alt=""
              className={`rounded aspect-video cursor-pointer ${cabin?.object} ${cabin.imageHeight}`}
            />
            <div className="flex flex-col justify-center flex-1 p-6">
              {/* <span className="text-xs uppercase">Elevator Cabins</span> */}
              <h3 className="text-3xl text-primary font-bold">{cabin.title}</h3>
              <p className="my-6">{cabin.description}</p>
              {cabin.buttonText && (
                <Link to="/service">
                  <button
                    type="button"
                    className="self-start px-8 text-primary py-3 md:text-lg cursor-pointer border-none rounded"
                  >
                    {cabin?.buttonText}
                  </button>
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ElevatorCabinsCard;
