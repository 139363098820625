import React from "react";
import fileUrl from "../../Assets/Brouchar.pdf"
const Brochure = () => {
  const handleDownload = () => {
    // Replace 'Broucher.pdf' with the actual filename of your PDF file
    // const fileUrl = process.env.PUBLIC_URL + "/Brouchar.pdf";

    // Create a temporary anchor element
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = "Brochure.pdf"; // Specify the name of the downloaded file
    anchor.click();

    // Remove the anchor from the DOM
    anchor.remove();
  };

  return (
    <div>
      <button
        type="button"
        className="px-8 bg-red-500 text-white py-3 m-2 md:text-lg border rounded"
        onClick={handleDownload}
      >
        Download Brochure PDF
      </button>
    </div>
  );
};

export default Brochure;
