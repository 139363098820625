import React from "react";
import CarpetCard from "../../Components/CarpetCard/CarpetCard";
import { carpetsData } from "../../Utils/Utils";
const Carpets = () => {
  
  return (
    <>
      <h1 className="text-primary ml-2">PVC Carpets</h1>
      <CarpetCard pvcMaterials={carpetsData?.pvcMaterials} />
      <CarpetCard pvcMaterials={carpetsData?.woodenMaterials} />
      <CarpetCard pvcMaterials={carpetsData?.marbleMaterials} />
      <CarpetCard pvcMaterials={carpetsData?.carpetMaterials} />
      <CarpetCard pvcMaterials={carpetsData?.flooringPattern} />
    </>
  );
};

export default Carpets;
