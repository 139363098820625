import React from 'react'
import { carpetsData } from '../../Utils/Utils'
import CarpetCard from '../../Components/CarpetCard/CarpetCard'
const CeilingPage = () => {
  return (
    <>
    <h1 className="text-primary ml-2">Ceiling</h1>
      <CarpetCard pvcMaterials={carpetsData?.ceilingData} />
    </>
  )
}

export default CeilingPage