import msImg1 from "../../Assets/cabins/msCabin1.PNG";
import msImg2 from "../../Assets/cabins/msCabin2.PNG";
import msImg3 from "../../Assets/cabins/msCabin3.PNG";
import msImg4 from "../../Assets/cabins/msCabin4.PNG";

export const msCabinCardData = [
  {
    title: "Modern Stainless Steel Cabin",
    imageHeight: "h-80",
    object: "object-contain",
    imageUrl: msImg1,
    imageSide: "right",
    imgClass: "w-2/5",
    description:
      "Upgrade your building's elevator experience with our modern stainless steel cabin. Combining sleek design with durability, our cabins offer a luxurious and reliable vertical transportation solution.",
  },
  {
    title: "Premium SS Elevator Cabin",
    imageHeight: "h-80",
    object: "object-contain",
    imageUrl: msImg2,
    imageSide: "left",
    imgClass: "w-2/5",
    description:
      "Experience sophistication and comfort with our premium SS elevator cabin. Crafted from high-quality stainless steel, our cabins provide a smooth and stylish riding experience for passengers.",
  },
  {
    title: "Customizable Stainless Steel Cabins",
    imageHeight: "h-80",
    object: "object-contain",
    imageUrl: msImg3,
    imageSide: "right",
    imgClass: "w-2/5",
    description:
      "Tailor your elevator cabin to match your building's aesthetic and requirements. Our customizable stainless steel cabins offer a range of options for finishes, lighting, and interior design.",
  },
  {
    title: "Elegant Stainless Steel Interior",
    imageHeight: "h-80",
    object: "object-contain",
    imageUrl: msImg4,
    imageSide: "left",
    imgClass: "w-2/5",
    description:
      "Enhance the interior of your elevator with our elegant stainless steel design. With attention to detail and quality craftsmanship, our cabins elevate the overall look and feel of your building.",
  },
];
