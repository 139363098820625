import React, { useEffect } from 'react'
import { EvCard } from '../../Components/EvCard'
import { ssCabinCardData } from '../../Utils/ssCabins/constant'
import EvTypography from '../../Components/EvTypography'
import ElevatorCabinsCard from '../../Components/CabinsCard/CabinsCard'

const SsCabinPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
  //   <div>
  //   <EvTypography
  //     additionalClass="text-center"
  //     type="h1"
  //     text="SS CABINS"
  //   />
  //   <div className="m-">
  //     <EvCard cardData={ssCabinCardData} descriptionClass="" />
  //   </div>
  // </div>
  <>
  <ElevatorCabinsCard data={ssCabinCardData}/>
  </>
  )
}

export default SsCabinPage