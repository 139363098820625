import React from "react";
import EvTypography from "../../Components/EvTypography";
import { EvCard } from "../../Components/EvCard";
import { premiumMsCabinCardData } from "../../Utils/PremiumMsCabin/constant";
import ElevatorCabinsCard from "../../Components/CabinsCard/CabinsCard";

const MsPremCabinPage = () => {
  return (
    // <div>
    //   <EvTypography
    //     additionalClass="text-center"
    //     type="h1"
    //     text="PREMIUM SS CABINS"
    //   />
    //   <div className="m-">
    //     <EvCard cardData={premiumMsCabinCardData} descriptionClass="" />
    //   </div>
    // </div>
    <>
    <ElevatorCabinsCard data={premiumMsCabinCardData}/>
    </>
  );
};

export default MsPremCabinPage;
