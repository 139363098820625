import React, { useEffect } from "react";
import EvTypography from "../../Components/EvTypography";
import { EvCard } from "../../Components/EvCard";
import { msCabinCardData } from "../../Utils/msCabins/constant";
import ElevatorCabinsCard from "../../Components/CabinsCard/CabinsCard";

const MsCabinPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    // <div>
    //   <EvTypography additionalClass="text-center" type="h1" text="MS CABINS" />
    //   <div className="m-">
    //     <EvCard cardData={msCabinCardData} descriptionClass="" />
    //   </div>
    // </div>
    <>
      <ElevatorCabinsCard data={msCabinCardData} />
    </>
  );
};

export default MsCabinPage;
