import React, { useEffect } from "react";
import BarcodeScan from "../../Components/BarcodeScan/BarcodeScan";

const BarcodePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex justify-center items-center">
      <BarcodeScan />
    </div>
  );
};

export default BarcodePage;
