import React, { useEffect, useState } from "react";

const OurProject = () => {
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  useEffect(() => {
    const interval1 = setInterval(() => {
      setCount1((prevCount) => {
        const targetCount = 70; // Replace with actual target count
        const increment = Math.ceil(targetCount / 100) * 5; // Adjust speed by changing the divisor
        const nextCount = prevCount + increment;
        return nextCount >= targetCount ? targetCount : nextCount;
      });
    }, 50); // Adjust speed by changing the interval duration

    const interval2 = setInterval(() => {
      setCount2((prevCount) => {
        const targetCount = 15; // Replace with actual target count
        const increment = Math.ceil(targetCount / 100); // Adjust speed by changing the divisor
        const nextCount = prevCount + increment;
        return nextCount >= targetCount ? targetCount : nextCount;
      });
    }, 50); // Adjust speed by changing the interval duration

    const interval3 = setInterval(() => {
      setCount3((prevCount) => {
        const targetCount = 75; // Replace with actual target count
        const increment = Math.ceil(targetCount / 100) * 5; // Adjust speed by changing the divisor
        const nextCount = prevCount + increment;
        return nextCount >= targetCount ? targetCount : nextCount;
      });
    }, 50); // Adjust speed by changing the interval duration

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, []);

  return (
    <div className="bg-primary">
      <h1 className=" text-center md:text-4xl text-2xl pt-4 text-white">
        Elevating You To New Heights!
      </h1>
      <div className="grid  px-8 md:py-8 py-4 my-6 grid-cols-1 md:grid-cols-3 gap-8">
        <div className="text-center bg-white rounded-2xl p-4 md:p-10">
          <h2 className=" md:text-5xl text-3xl text-center font-bold text-primary">
            {count1} +
          </h2>
          <p className="md:text-2xl text-center font-bold">Complete Project</p>
        </div>
        <div className="text-center bg-white rounded-2xl p-4 md:p-10">
          <h2 className="md:text-5xl font-bold tracking-tight text-3xl text-center  text-primary">
            {count2} +
          </h2>
          <p className="md:text-2xl text-center font-bold">
            Years of Experience
          </p>
        </div>
        <div className="text-center bg-white rounded-2xl p-4 md:p-10">
          <h2 className="md:text-5xl text-3xl text-center font-bold text-primary">
            {count3} +
          </h2>
          <p className="md:text-2xl text-center font-bold">Happy Clients</p>
        </div>
      </div>
    </div>
  );
};

export default OurProject;
