import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const LandingCards = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    // <section className=" text-primary ">
    //   <div className="container grid grid-cols-12 mx-auto lg:pt-6">
    //     {/* Image Section */}
    //     <div className="flex flex-col justify-center col-span-12 md:col-span-6 lg:col-span-6  bg-no-repeat bg-cover">
    //       <img
    //         src="https://5.imimg.com/data5/UP/TI/MQ/SELLER-3570397/ss-304-elevator-cabin-1000x1000.jpg"
    //         alt="Elevator Cabin"
    //         style={{ mixBlendMode: "multiply", maxHeight: "500px" }}
    //       />
    //     </div>

    //     {/* Content Section */}
    //     <div className="flex flex-col col-span-12 py-4 px-1 divide-y md:col-span-6 lg:col-span-6 divide-gray-700">
    //       <div className="space-y-2">
    //         <h1 className="text-3xl py-4 font-bold">SS Elevator Cabins</h1>
    //         <p className="">
    //           Elevate your building's aesthetics with our premium SS elevator
    //           cabins. Crafted from high-quality stainless steel, our cabins
    //           combine durability, style, and functionality. Designed to
    //           withstand the test of time, our SS cabins offer a luxurious
    //           riding experience while ensuring passenger safety and comfort.
    //           Discover our range of customizable options to tailor the cabin
    //           interiors to your building's unique style and requirements.
    //         </p>
    //         <a
    //           href="#"
    //           rel="noopener noreferrer"
    //           className="inline-flex items-center py-2 space-x-2 text-sm text-violet-400"
    //         >
    //           <span>Read more</span>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             viewBox="0 0 20 20"
    //             fill="currentColor"
    //             className="w-4 h-4"
    //           >
    //             <path
    //               fillRule="evenodd"
    //               d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
    //               clipRule="evenodd"
    //             ></path>
    //           </svg>
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <section className="w-full md:py-24">
      <div className="grid grid-cols lg:grid-cols-2  items-start">
        <div className="w-90 px-4 md:px-2 text-left">
          <h2 className="text-3xl lg:text-4xl font-bold text-primary mb-4">
            Elevator Cabins
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            Elevate your building's aesthetics with our premium elevator cabins.
            Crafted from high-quality materials and designed with precision.
          </p>
          <div className="space-y-6">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-primary text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 ">
                  Modern Stainless Steel SS Cabin
                </h3>
                <p className="text-gray-600 ">
                  Upgrade your building's elevator experience with our modern
                  stainless steel cabin. Combining sleek design with durability,
                  our cabins offer a luxurious and reliable vertical
                  transportation solution.
                </p>
              </div>
            </div>
            <Link className="no-underline flex justify-end" to="ssCabinPage">
              <button
                type="button"
                className="self-start px-8 text-primary py-3 md:text-lg cursor-pointer border-none rounded"
              >
                Learn More
              </button>
            </Link>

            <div className="flex mt-2 items-center space-x-4">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-primary text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <h3 className="text-xl lg:text-2xl font-semibold text-gray-800">
                  Customizable Stainless Steel MS Cabins
                </h3>
                <p className="text-gray-600 ">
                  Tailor your elevator cabin to match your building's aesthetic
                  and requirements. Our customizable stainless steel cabins
                  offer a range of options for finishes, lighting, and interior
                  design.
                </p>
              </div>
            </div>
            <Link className="no-underline flex justify-end" to="msCabinPage">
              <button
                type="button"
                className="self-start px-8 text-primary py-3 md:text-lg cursor-pointer border-none rounded"
              >
                Learn More
              </button>
            </Link>
          </div>
        </div>
        <div className="lg:order-first  w-full flex justify-center">
          <div className="h-96 w-full md:py-0 py-4 flex justify-center">
            <img
              src="https://img-new.cgtrader.com/items/2677186/f2e982085c/passenger-elevator-3d-model-max-obj.jpg"
              alt="Elevator Image"
              className="md:w-90 w-80 h-full rounded-lg shadow-lg object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingCards;
