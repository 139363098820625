import callIcon from "../../Assets/social icons/Phone .png";
import locationIcon from "../../Assets/social icons/add.png";
import gmailIcon from "../../Assets/social icons/mail.png";
import facebookIcon from "../../Assets/social icons/face.png";
import instagramIcon from "../../Assets/social icons/inst.png";
import linkedIcon from "../../Assets/social icons/l in.png";
import twitterIcon from "../../Assets/social icons/twit.png";
import footerLogo from "../../Assets/footerLogo.png";
import { Link } from "react-router-dom";
const FooterSection = () => (
  <footer className="px-4 mt-8 bg-bgFooter pt-12 pb-8 text-white border-t border-gray-200">
    <div className="grid  grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 md:grid-cols-2">
      <div className="w-full text-center sm:pl-0 lg:text-left">
        <a
          href="/"
          className="flex justify-start  text-center sm:text-center lg:text-left "
        >
          <span className="flex  w-full  justify-center items-center">
            <img src={footerLogo} width={230} alt="logo" />
          </span>
        </a>
        <p className="mt-6 md:mt-0 w-full text-white text-center">
          Safe Luxurious Experience
        </p>
      </div>

      <section className="flex flex-col w-full  font-medium text-white justify-start list-none">
        <div className="inline-block font-bold tracking-wide text-white uppercase mt-4 md:mt-0">
          Contact Us
        </div>

        <div className="flex items-center">
          <img className="" src={locationIcon} alt="location" width={20} />
          <a
            href="https://www.google.com/maps?q=52+gates+elevators+Pvt.+Ltd."
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block py-2 pl-2 no-underline text-decoration-none text-white"
          >
            Plot No 2-1-10, Jubilee Park, Aurangabad 431001, Maharashtra.
          </a>
        </div>
        <div className="flex  items-center">
          <img className="" src={callIcon} alt="call" width={20} />
          <span className="inline-block md:px-2 pl-2 py-2 no-underline">
            <a
              href="tel:8055000052"
              className="my-0 py-0 text-white no-underline text-decoration-none"
            >
              +91-8055000052
            </a>{" "}
            <br />
            <a
              href="tel:8412000052"
              className="my-0 text-white py-0 no-underline text-decoration-none"
            >
              +91-8412000052
            </a>
          </span>
        </div>
        <div className="flex items-center">
          <img className="" src={gmailIcon} alt="gmail" width={20} />
          <a
            href="mailto:52gateselevators@gmail.com"
            className="inline-block pl-2 py-2 text-white no-underline text-decoration-none"
          >
            52gateselevators@gmail.com
          </a>
        </div>
      </section>
      {/* product section abhi ke liye nhi chahiye but baad me client hona bolta */}
      {/* <ul className="flex  justify-start mt-0 flex-col w-full p-0 font-medium list-none">
        <li className="inline-block text-primary md:px-3  lg:py-2  font-bold tracking-wide uppercase">
          PRODUCTS
        </li>
        <li>
          <a
            href="#_"
            className="inline-block md:px-3 py-2  text-white no-underline "
          >
            Elevator Doors
          </a>
        </li>
        <li>
          <a
            href="#_"
            className="inline-block md:px-3 py-2 text-white no-underline "
          >
            Elevator Cabins
          </a>
        </li>
        <li>
          <a
            href="#_"
            className="inline-block md:px-3 py-2 text-white no-underline"
          >
            Elevator Ceiling
          </a>
        </li>
        <li>
          <a
            href="#_"
            className="inline-block md:px-3 py-2 text-white no-underline"
          >
            Elevator Flooring
          </a>
        </li>
        <li>
          <a
            href="#_"
            className="inline-block md:px-3 py-2 text-white no-underline"
          >
            Elevator Components
          </a>
        </li>
      </ul> */}
    </div>
    <section className="grid md:grid-cols-2 sm:grid-cols-1 ">
      <div></div>
      <div className="flex justify-center flex-col    text-white">
        <div className="md:pt-4 mt-2 inline-block font-bold uppercase">
          FOLLOW US
        </div>
        <div className="flex justify-around md:justify-between md:w-1/3 mt-2 mb-2">
          <Link to="https://www.facebook.com/story.php?story_fbid=pfbid04DCpgx3sKpbbvSftrWW8p47J5ZD89g4oApUFEKDMsSfiufQHwUDn5MGtsiEdwfUUl&id=61557661203767&sfnsn=wa&mibextid=6aamW6&_rdr">
            <img src={facebookIcon} alt="facebook" width={25} height={25} />
          </Link>
          <Link to="https://www.instagram.com/52gateselevators?utm_source=qr&igsh=djluMjd0NGoyaGU5">
            <img src={instagramIcon} alt="instagram" width={25} />
          </Link>
          <img src={linkedIcon} alt="linkedin" width={25} height={25} />
          <img src={twitterIcon} alt="twitter" width={25} height={25} />
        </div>
      </div>
    </section>
    <hr />
    <div className="flex flex-col justify-center text-center">
      <div className="md:pt-2 mt-2  border-gray-100">
        © {new Date().getFullYear()} The 52 Gates Elevator Pvt.Ltd. All rights
        reserved.
      </div>
      <div className="pt-2 mt-2  border-t border-gray-100">
        Designed By{" "}
        <a
          className="text-white font-bold"
          href="https://www.linkedin.com/in/sayyed-sohel-4621862b3/"
        >
          Sayyed Sohel Ahmed
        </a>
      </div>
    </div>
  </footer>
);

export default FooterSection;
