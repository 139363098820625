import React from "react";

const Inputs = ({ label, name, placeholder, required, type, additionalClass,onChange,value }) => {
  return (
    <>
      <div className="ml-1" >
        {label && (
          <label
            htmlFor={name}
            className="block text-sm font-medium text-primary "
          >
            {label}
          </label>
        )}
        <input
          type={type}
          id={name}
          name={name}
          className={` shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full py-2.5  pl-1.5 placeholder:1  ${additionalClass} `}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default Inputs;
