import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as RightArrow } from "../Assets/right-side-icon.svg";
import { ReactComponent as DownArrow } from "../Assets/arrow-down-icon.svg";
import { ReactComponent as UpArrow } from "../Assets/arrow-up-icon.svg";
const NestedDropdown = (props) => {
  const dropdownRef = useRef(null);
  const NestedDropdownRouting = [
    { name: "Capsule Elevator", to: "/capsuleElevator" },
    { name: "Doors", to: "/door" },
    { name: "Ceiling", to: "/ceilingPage" },
    { name: "PVC Capets", to: "/carpet" },
    // { name: "Components", to: "/componentsPage" },
    {
      name: "Lift Cabins",
      nestedOptions: [
        { name: "SS Cabins", to: "/ssCabinPage" },
        { name: "Premium SS Cabins", to: "/ssPremCabinPage" },
        { name: "MS Cabins", to: "/msCabinPage" },
        { name: "Premium MS Cabins", to: "/msPremCabinPage" },
      ],
    },
  ];
  const HandleNestedList = (nestedOpt, ind) => {
    return (
      <li>
        <NavLink
          className="flex items-center justify-between no-underline outline-none hover:text-white text-primary"
          key={ind}
          to={nestedOpt?.to}
        >
          {nestedOpt.name}
        </NavLink>
      </li>
    );
  };

  return (
    <div>
      <div>
        <div>
          <p
            className="flex w-full justify-between items-center border-none font active:text-primary hover:text-primary bg-white text-gray-500 "
            onMouseEnter={props?.handleMouseEnter}
            onMouseLeave={props?.handleMouseLeave}
            onClick={() => props?.setShow(!props?.show)}
            type="button"
          >
            <span className="md:pr-1 ">Products</span>

            {props?.show ? (
              <UpArrow className="fill-gray-600 hover:fill-primary" />
              ) : (
              <DownArrow className="fill-gray-600 hover:fill-primary" />
            )}
          </p>
          {console.log(props?.show)}
          <div
            className="relative"
            ref={dropdownRef}
            onMouseEnter={props?.handleMouseEnter}
            onMouseLeave={props?.handleMouseLeave}
          >
            {props?.show && (
              <div className="bg-white rounded-md p-3 min-w-[210px]  absolute  z-10 ">
                <ul className=" list-none p-0 m-0 hover:[&>li]:text-white [&>li]:text-sm [&>li]:cursor-pointer [&>li]:px-2 [&>li]:py-1 [&>li]:rounded-md [&>li]:transition-all hover:[&>li]:bg-primary  active:[&>li]:bg-gray-300 active:[&>li]:scale-[0.99]">
                  {NestedDropdownRouting.map((item, index) =>
                    !item?.nestedOptions ? (
                      <li onMouseLeave={() => props?.setMenu(false)}>
                        <NavLink
                          className=" flex items-center justify-between no-underline outline-none hover:text-white text-primary p-0"
                          key={index}
                          to={item.to}
                        >
                          {item.name}
                        </NavLink>
                      </li>
                    ) : (
                      <>
                        <li
                          className="flex items-center justify-between text-primary"
                          onMouseEnter={() => props?.setMenu(true)}
                          onClick={() => props?.setMenu(!props?.menu)}
                        >
                          {item.name}
                          {/* <span className="">&#11208;</span> */}
                          {props?.menu ? (
                            <UpArrow className="" />
                          ) : (
                            <RightArrow className="" />
                          )}
                        </li>

                        {props?.menu && (
                          <div
                            className="bg-white  rounded-md max-w-[180px] w-full p-3 absolute -right-[100px] md:-right-[170px] sm:-right-[187px]
                      -bottom-20 [&>li]:text-white [&>li]:text-sm [&>li]:cursor-pointer [&>li]:px-2 [&>li]:py-1 [&>li]:rounded-md [&>li]:transition-all hover:[&>li]:bg-primary active:[&>li]:bg-gray-700 active:[&>li]:scale-[0.99]"
                          >
                            {item?.nestedOptions.map((nestedOpt, ind) =>
                              HandleNestedList(nestedOpt, ind)
                            )}
                          </div>
                        )}
                      </>
                    )
                  )}

                  <div className="w-full border border-gray-700 my-1"></div>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NestedDropdown;
