import React from "react";
import Brochure from "../Brochure/Brochure";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <section>
      <div className="bg-primary mt-6 text-white border-2xl">
        <div className="flex flex-col items-center px-4 py-16 pb-24 mx-auto text-center lg:pb-56 md:py-32 md:px-10 lg:px-32 ">
          <h1 className="md:text-5xl font-bold leading-none text-2xl xl:max-w-3xl ">
            Elevating Your Building Experience
          </h1>
          <p className="mt-6 mb-8 text-lg sm:mb-12 xl:max-w-3xl ">
            Experience seamless vertical transportation with our cutting-edge
            elevator solutions. We prioritize safety, efficiency, and
            sustainability to provide unparalleled service to your building.
          </p>
          <div className="flex justify-center">
            <Link to="/service">
              <button
                type="button"
                className="px-8 text-primary py-3 m-2 md:text-lg font-semibold rounded"
              >
                Explore Our Services
              </button>
            </Link>
            <Brochure />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <img
          src="https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AA1hZSui.img"
          alt=""
          className="w-4/6 mx-auto mb-12 -mt-20 rounded-lg shadow-md lg:-mt-40"
        />
      </div>
    </section>
  );
};

export default HeroSection;
