import React from "react";
import cabinsPdfUrl from "../../Assets/Cabins.pdf"; // Make sure this path is correct

const CabinsPdf = () => {
  const handleDownload = () => {
    const anchor = document.createElement("a");
    anchor.href = cabinsPdfUrl;
    anchor.download = "CabinsDetails.pdf";
    anchor.click();
    anchor.remove();
  };

  return (
    <div className="py-12 bg-primary text-center">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-white mb-4">Cabin Details</h2>
        <p className="text-lg text-white mb-6">
          Learn more about our range of cabin designs. Each design is tailored
          to meet your specific needs, ensuring comfort and safety with the
          latest technology and highest quality materials.
        </p>
        <button
          onClick={handleDownload}
          type="button"
          className="px-8 bg-red-500 text-white py-3 m-2 md:text-lg border rounded"
        >
          Download Cabin Details PDF
        </button>
        {/* <button
          onClick={handleDownload}
          className="px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300"
        >
          Download Cabin Details PDF
        </button> */}
      </div>
    </div>
  );
};

export default CabinsPdf;
