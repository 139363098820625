import React, { useEffect } from "react";
import { EvCard } from "../../Components/EvCard";
import { services } from "../../Utils/services/constant";
import ElevatorCabinsCard from "../../Components/CabinsCard/CabinsCard";
import CabinsPdf from "../../Components/CabinsPdf/CabinsPdf";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <CabinsPdf />
      <ElevatorCabinsCard data={services?.servicesCardData?.data} />
    </>
  );
};

export default Services;
