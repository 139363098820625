import capsuleImg1 from "../../Assets/cabins/capsuleEv1.PNG";
import capsuleImg2 from "../../Assets/cabins/capsuleEv2.PNG";
import capsuleImg3 from "../../Assets/cabins/capsuleEv3.PNG";
import capsuleImg4 from "../../Assets/cabins/capsuleEv4.PNG";

export const capsuleElevatorCardData = [
  {
    title: "Modern Capsule Elevator",
    imageUrl: capsuleImg1,
    object: "object-contain",
   imageHeight: "h-80",
    description:
      "Upgrade your building's elevator experience with our modern capsule elevator. Combining sleek design with advanced technology, our elevators offer a luxurious and efficient vertical transportation solution.",
  },
  {
    title: "Premium Capsule Elevator",
    imageUrl: capsuleImg2,
    object: "object-contain",
   imageHeight: "h-80",
    description:
      "Experience sophistication and comfort with our premium capsule elevator. Crafted with premium materials and state-of-the-art features, our elevators provide a smooth and stylish riding experience for passengers.",
  },
  {
    title: "Customizable Capsule Elevator",
    imageUrl: capsuleImg3,
    object: "object-contain",
     imageHeight: "h-80",
    description:
      "Tailor your elevator to match your building's aesthetic and requirements with our customizable capsule elevator. Choose from a variety of finishes, lighting options, and interior designs to create the perfect elevator for your space.",
  },
  {
    title: "Elegant Capsule Elevator Interior",
    imageUrl: capsuleImg4,
    object: "object-contain",
   imageHeight: "h-80",
    description:
      "Enhance the interior of your elevator with our elegant capsule elevator design. With attention to detail and quality craftsmanship, our elevators elevate the overall look and feel of your building's interior.",
  },
];
