import React, { useEffect } from "react";
import Line from "../Line/Line";

const CarpetCard = ({ pvcMaterials }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="flex justify-start ml-4 items-center">
        <Line />
        <h2>{pvcMaterials?.title}</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-center ">
        {pvcMaterials?.data?.map((item, index) => (
          <div key={index} className="p-4 flex flex-col items-center">
            <img src={item.image} alt="floor" className="w-90" />
            <p className="text-center">{item.batch}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarpetCard;
