import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const CarouselCard = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const data = [
    {
      img: "https://5.imimg.com/data5/SELLER/Default/2021/11/JP/KT/GQ/121504112/ss-elevator-cabin-1000x1000.jpg",
      to: "/service",
    },
    {
      img: "https://4.imimg.com/data4/FL/CI/MY-25141027/passenger-elevator-cabins-500x500.jpg",
      to: "/service",
    },
    {
      img: "https://3.imimg.com/data3/AX/PF/MY-10715304/passenger-lift-and-elevators-500x500.jpg",
      to: "/service",
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFEDXu4BhEX5BpXW5neukvIsrQXAs1POzk3lP4TCIu5P6rwg6qE_VhRUb30CawpJs7DVU&usqp=CAU",
      to: "/service",
    },
    {
      img: "https://5.imimg.com/data5/DA/QT/NU/SELLER-66324247/mirror-finish-stainless-steel-elevator-cabin.jpg",
      to: "/service",
    },
    {
      img: "https://d91ztqmtx7u1k.cloudfront.net/ClientContent/Images/Medium/20230419044230-297b0c8a-e424-4f54-a671-f909e0fb2d4e.jpg",
      to: "/service",
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-uun31nV8iquoze9_5L06HTXkV45384zfncbp7CRas1KoOrFTALulVLIwPWQCY5dnNSc&usqp=CAU",
      to: "/service",
    },
   
  ];

  return (
    <Carousel
      responsive={responsive}
      swipeable={false}
      draggable={false}
      showDots={true}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      keyBoardControl={true}
      customTransition="all .8"
      transitionDuration={1000}
      containerClass="carousel-container"
      dotListClass="custom-dot-list-style"
    >
      {data.map((item, index) => (
        <section key={index} className="py-6 sm:py-12">
          <div className="container mx-auto space-y-8">
            <article className="flex flex-col">
              
                <img
                  alt="Lift Elevator"
                  className="object-cover w-full h-55"
                  src={item.img}
                />
             
            </article>
          </div>
        </section>
      ))}
    </Carousel>
  );
};

export default CarouselCard;
