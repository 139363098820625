import "./App.css";
import WebRoutes from "./Routes/WebRoutes";

function App() {
  return (
    <>
      <WebRoutes />
    </>
  );
}

export default App;
