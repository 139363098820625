import commercialImg from "../../Assets/serviceImg/commercialImg.PNG";
import hospitalImg from "../../Assets/serviceImg/hospitalImg.PNG";
import industrialImg from "../../Assets/serviceImg/industrialImg.PNG";
import homeImg from "../../Assets/serviceImg/homeImg.PNG";
import chairImg from "../../Assets/serviceImg/chairImg.PNG";
import escalatorImg from "../../Assets/serviceImg/escalatorImg.jpg";
import glassImg from "../../Assets/serviceImg/glassImg.PNG";
import doorsImg from "../../Assets/serviceImg/doorsImg.PNG";
import capLopImg from "../../Assets/serviceImg/capLopImg.PNG";
import safetyImg from "../../Assets/serviceImg/safetyImg.PNG";
import helplineImg from "../../Assets/serviceImg/helplineImg.PNG";

export const services = {
  servicesCardData: {
    title: "Our Services",
    data: [
      {
        title: "Commercial Elevator",
        imageHeight: "h-80",
        object: "object-cover",
        imageUrl: commercialImg,
        description:
          "Enhance vertical transportation in commercial buildings with our robust elevator solutions. Our commercial elevators are designed for high traffic and offer reliable performance for your business needs.",
      },
      {
        title: "Industrial Elevator",
        imageHeight: "h-80",
        object: "object-cover",
        imageUrl: industrialImg,
        imageSide: "left",
        description:
          "Optimize vertical mobility in industrial facilities with our durable and efficient industrial elevators. Designed to withstand heavy loads and harsh environments, our elevators ensure smooth operations in industrial settings.",
      },
      {
        title: "Hospital Elevator",
        imageHeight: "h-80",
        object: "object-cover",
        imageUrl: hospitalImg,
        description:
          "Prioritize patient care and comfort with our specialized hospital elevators. Equipped with advanced features and smooth operation, our hospital elevators provide safe and efficient transportation within healthcare facilities.",
      },
      {
        title: "Home Elevator",
        imageHeight: "h-80",
        object: "object-cover",
        imageUrl: homeImg,
        imageSide: "left",
        description:
          "Transform residential living spaces with our elegant and functional home elevators. Whether it's for accessibility or convenience, our home elevators offer seamless vertical transportation for homeowners.",
      },
      {
        title: "Chair Elevator",
        imageHeight: "h-80",
        object: "object-cover",
        imageUrl: chairImg,
        description:
          "Provide accessibility solutions for individuals with mobility challenges with our innovative chair elevators. Compact and easy to install, our chair elevators offer independence and convenience for users.",
      },
      {
        title: "Escalator Elevator",
        imageHeight: "h-80",
        object: "object-cover",
        imageUrl: escalatorImg,
        imageSide: "left",
        description:
          "Enhance the flow of foot traffic in public spaces with our reliable escalator elevators. Designed for efficiency and safety, our escalators offer smooth vertical transportation for pedestrians in malls, airports, and other public areas.",
      },
      {
        title: "Glass Elevator",
        imageHeight: "h-80",
        object: "object-contain",
        imageUrl: glassImg,
        description:
          "Add a touch of elegance to your building's interior with our stylish glass elevators. Featuring sleek designs and transparent cabins, our glass elevators elevate the architectural aesthetics of any space.",
      },
      {
        title: "Door Types",
        imageHeight: "h-80",
        object: "object-contain",
        imageUrl: doorsImg,
        imageSide: "left",
        description:
          "Customize the entrance and exit experience of your elevator with our versatile door types. From traditional swing doors to modern automatic sliding doors, we offer a variety of door options to suit your building's design and functionality requirements.",
      },
      {
        title: "COP LOP",
        imageHeight: "h-80",
        object: "object-cover",
        imageUrl: capLopImg,
        description:
          "Ensure seamless operation and control of your elevator with our comprehensive COP (Car Operating Panel) and LOP (Landing Operating Panel) solutions. Designed for intuitive user interface and reliable performance, our COP and LOP systems optimize elevator functionality and accessibility.",
      },
      {
        title: "Safety Systems",
        imageHeight: "h-80",
        object: "object-cover",
        imageUrl: safetyImg,
        imageSide: "left",
        description:
          "Prioritize passenger safety and peace of mind with our advanced elevator safety systems. From emergency brakes to door sensors, our safety systems are engineered to detect and mitigate potential hazards, ensuring a secure riding experience for all passengers.",
      },
      {
        title: "After Sales Service",
        imageHeight: "h-80",
        object: "object-cover",
        imageUrl: helplineImg,
        description:
          "Experience continued support and maintenance for your elevator systems with our dedicated after-sales service team. From routine inspections to emergency repairs, we provide comprehensive assistance to ensure the optimal performance and longevity of your elevators.",
      },
    ],
  },
  elevatorCabins: {
    title: "Our Services",
    data: [
      {
        id: 1,
        imageHeight: "h-80",
        object: "object-cover",
        imageUrl:
          "https://avatars.dzeninfra.ru/get-zen_doc/5235248/pub_628896b78e8d844b51b03069_62889c1df5a7bd7b1315745f/scale_1200",
        title: "Premium Elevator Cabins",
        description:
          "Elevate your building's aesthetic with our modern and luxurious elevator cabins. Designed for comfort, style, and efficiency.",
        buttonText: "Learn More",
      },
      {
        id: 2,
        imageHeight: "h-80",
        object: "object-cover",
        imageUrl:
          "https://dailyhornet.com/wp-content/uploads/2022/01/elevator-recall-2.jpg",
        title: "Tailored Elevator Interiors",
        description:
          "Personalize your elevator cabins to match your building's unique style and requirements. Choose from a range of materials, finishes, and features.",
        buttonText: "Learn More",
      },
      {
        id: 3,
        imageHeight: "h-80",
        object: "object-cover",
        imageUrl: "https://thumbs.dreamstime.com/b/going-down-1561573.jpg",
        title: "Elevator Cabin Security",
        description:
          "Prioritize passenger safety with our advanced security features. From emergency alarms to anti-trap sensors, we ensure a secure riding experience.",
        buttonText: "Learn More",
      },
    ],
  },
};
