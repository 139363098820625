import React from "react";
import EvTypography from "../../Components/EvTypography";
import { premiumSsCabinCardData } from "../../Utils/PremiumSSCabins/constant";
import { EvCard } from "../../Components/EvCard";
import ElevatorCabinsCard from "../../Components/CabinsCard/CabinsCard";

const SsPremCabinPage = () => {
  return (
    // <div>
    //   <EvTypography
    //     additionalClass="text-center"
    //     type="h1"
    //     text="PREMIUM SS CABINS"
    //   />
    //   <div className="m-">
    //     <EvCard cardData={premiumSsCabinCardData} descriptionClass="" />
    //   </div>
    // </div>
    <>
    <ElevatorCabinsCard data={premiumSsCabinCardData}/>
    </>
  );
};

export default SsPremCabinPage;
