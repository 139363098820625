import { Route, Routes } from "react-router-dom";
import NavbarSection from "../Components/NavbarSection/NavbarSection";
import FooterSection from "../Components/FooterSection/FooterSection";
import WhatsAppIcon from "../Components/WhatsAppIcon/WhatsAppIcon";
import About from "../Pages/AboutUs/AboutUs";
import Services from "../Pages/Services/Services";
import ContactUs from "../Pages/ContactUs/ContactUs";
import Carpets from "../Pages/Carpets/Carpets";
import CeilingPage from "../Pages/CeilingPage/CeilingPage";
import SsCabinPage from "../Pages/SsCabinPage/SsCabinPage";
import SsPremCabinPage from "../Pages/SsPremCabinPage/SsPremCabinPage";
import MsCabinPage from "../Pages/MsCabinPage/MsCabinPage";
import MsPremCabinPage from "../Pages/MsPremCabinPage/MsPremCabinPage";
import Doors from "../Pages/Doors/Doors";
import CapsuleElevator from "../Pages/CapsuleElevator/CapsuleElevator";
import ComponentsPage from "../Pages/ComponentsPage/ComponentsPage";
import Home from "../Pages/Home/Home";
import BarcodePage from "../Pages/BarcodePage/BarcodePage";

const WebRoutes = () => {

  return (
    <>
      <NavbarSection />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Services />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/carpet" element={<Carpets />} />
        <Route path="/ceilingPage" element={<CeilingPage />} />
        <Route path="/ssCabinPage" element={<SsCabinPage />} />
        <Route path="/ssPremCabinPage" element={<SsPremCabinPage />} />
        <Route path="/msCabinPage" element={<MsCabinPage />} />
        <Route path="/msPremCabinPage" element={<MsPremCabinPage />} />
        <Route path="/door" element={<Doors />} />
        <Route path="/capsuleElevator" element={<CapsuleElevator />} />
        <Route path="/componentsPage" element={<ComponentsPage />} />
        <Route path="/barcodePage" element={<BarcodePage />} />
      </Routes>
      <WhatsAppIcon />
      <FooterSection />
    </>
  );
};

export default WebRoutes;
