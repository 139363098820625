import premMsImg1 from "../../Assets/cabins/premMsCabin1.PNG";
import premMsImg2 from "../../Assets/cabins/premMsCabin2.PNG";
import premMsImg3 from "../../Assets/cabins/premMsCabin3.PNG";
import premMsImg4 from "../../Assets/cabins/premMsCabin4.PNG";

import cabin1Img from "../../Assets/cabins/premMsCabin1.PNG";
import cabin2Img from "../../Assets/cabins/premMsCabin2.PNG";
import cabin3Img from "../../Assets/cabins/premMsCabin3.PNG";
import cabin4Img from "../../Assets/cabins/premMsCabin4.PNG";

export const premiumMsCabinCardData = [
  {
    title: "Luxury Mild Steel Cabin",
    imageUrl: cabin1Img,
    imageHeight: "h-80",
    object: "object-contain",
    imgClass: "w-2/5",
    description:
      "Experience luxury with our premium mild steel elevator cabins. Crafted with high-quality materials and elegant design, our cabins offer a comfortable and stylish vertical transportation solution.",
  },
  {
    title: "Sleek MS Elevator Cabin",
    imageUrl: cabin2Img,
    imageHeight: "h-80",
    object: "object-contain",
    imgClass: "w-2/5",
    description:
      "Enhance your building's aesthetic with our sleek mild steel elevator cabins. Featuring modern design elements and smooth finishes, our cabins elevate the ambiance of any space.",
  },
  {
    title: "Custom Mild Steel Cabins",
    imageUrl: cabin3Img,
    imageHeight: "h-80",
    object: "object-contain",
    imgClass: "w-2/5",
    description:
      "Personalize your elevator cabins to suit your building's style and requirements. Our custom mild steel cabins offer a variety of customization options for a unique and tailored riding experience.",
  },
  {
    title: "Premium MS Cabin Design",
    imageUrl: cabin4Img,
    imageHeight: "h-80",
    object: "object-contain",
    imgClass: "w-2/5",
    description:
      "Elevate the interior of your building with our premium mild steel cabin designs. With meticulous attention to detail and superior craftsmanship, our cabins add sophistication and charm to any environment.",
  },
];

